// BootstrapのJavaScript側の機能を読み込む
import 'bootstrap';
import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';

// スタイルシートを読み込む
import './index.scss';

//自由記述のJSを以下に書く
import cssVars from 'css-vars-ponyfill';
import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/regular';